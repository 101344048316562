import { useAllPages } from "./useAllPages";

export const useSinglePage = (
  pageId: GatsbyTypes.Maybe<string>
): GatsbyTypes.WpPageFieldsFragment | undefined => {
  const allPages = useAllPages();

  const singlePage = allPages.find((page) => page.id === pageId);

  return singlePage;
};
