import { Card, Grid, Typography } from "@material-ui/core";
import { RVOTeamMemberCardProps } from "./RVOTeamMemberCardInterfaces";
import { useRVOTeamMemberCardStyles } from "./RVOTeamMemberCardStyles";

const RVOTeamMemberCard = (props: RVOTeamMemberCardProps): JSX.Element => {
  const classes = useRVOTeamMemberCardStyles();

  return (
    <Card className={classes.card}>
      <Grid container={true} className={classes.gridContainer}>
        <Grid item={true} xs={5} sm={5} md={5} className={classes.leftContent}>
          <img src={props.avatarSrc} />
        </Grid>

        <Grid item={true} xs={7} sm={7} md={7} className={classes.rightContent}>
          <Typography variant="h3" component="h3" className={classes.name}>
            {props.name}
          </Typography>

          <Typography
            variant="subtitle1"
            component="p"
            className={classes.position}
          >
            {props.position}
          </Typography>

          <Typography variant="body1" className={classes.description}>
            {props.description}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default RVOTeamMemberCard;
