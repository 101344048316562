import { createStyles, makeStyles } from "@material-ui/core";

export const useRVORoadmapStyles = makeStyles((theme) =>
  createStyles({
    stepButton: {
      transition: "all .2s ease-in-out",
      "&:hover": {
        transform: "scale(1.08)",
      },
    },
    wrapper: {
      "& *": { backgroundColor: "transparent" },
    },
    buttonWrapper: {
      marginBottom: theme.spacing(3),
      "& a:first-child": {
        marginRight: theme.spacing(1),
      },
    },
    stepper: {
      textAlign: "center",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      bgcolor: theme.palette.common.black,
    },
    labelContainer: { marginLeft: "20px", marginRight: "20px" },
    connector: {
      color: theme.palette.secondary.dark,
    },
    connectorCompleted: {
      color: theme.palette.warning.dark,
    },
    connectorLine: {
      borderColor: "currentColor",
      borderTopWidth: 3,
      borderRadius: 1,
    },
    connectorLineVertical: {
      borderLeftWidth: 0,
    },
    stepperVertical: { gap: 40 },
  })
);
