import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { wordpressUrl } from "../../../../config/backendUrl";
import RVOTeamMemberCard from "../../02-molecules/RVOTeamMemberCard/RVOTeamMemberCard";
import { useRVOTeamMemberGridStyles } from "./RVOTeamMemberGridStyles";

const RVOTeamMemberGrid = (): JSX.Element => {
  const { t } = useTranslation();
  const classes = useRVOTeamMemberGridStyles();

  return (
    <Grid container={true} spacing={3} className={classes.gridContainer}>
      <Grid item={true} sm={12} md={6}>
        <RVOTeamMemberCard
          name={t("aboutUsPage.team.members.felix.name")}
          avatarSrc={`${wordpressUrl}/wp-content/uploads/2022/08/RS2022-04_00069-1-scaled.jpg`}
          position={t("aboutUsPage.team.members.felix.position")}
          description={t("aboutUsPage.team.members.felix.description")}
        />
      </Grid>

      <Grid item={true} sm={12} md={6}>
        <RVOTeamMemberCard
          name={t("aboutUsPage.team.members.stefan.name")}
          avatarSrc={`${wordpressUrl}/wp-content/uploads/2022/08/RS2022-04_00079-scaled.jpg`}
          position={t("aboutUsPage.team.members.stefan.position")}
          description={t("aboutUsPage.team.members.stefan.description")}
        />
      </Grid>

      <Grid item={true} sm={12} md={6}>
        <RVOTeamMemberCard
          name={t("aboutUsPage.team.members.hubi.name")}
          avatarSrc={`${wordpressUrl}/wp-content/uploads/2022/08/RS2022-04_00085-scaled.jpg`}
          position={t("aboutUsPage.team.members.hubi.position")}
          description={t("aboutUsPage.team.members.hubi.description")}
        />
      </Grid>

      <Grid item={true} sm={12} md={6}>
        <RVOTeamMemberCard
          name={t("aboutUsPage.team.members.reik.name")}
          avatarSrc={`${wordpressUrl}/wp-content/uploads/2022/08/RS2022-04_00162-scaled.jpg`}
          position={t("aboutUsPage.team.members.reik.position")}
          description={t("aboutUsPage.team.members.reik.description")}
        />
      </Grid>
    </Grid>
  );
};

export default RVOTeamMemberGrid;
