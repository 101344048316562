import { ComponentWithChildrenProps } from "@nvon/react-toolbox";
import {
  AnimationProps,
  motion,
  useAnimationControls,
  useInView,
} from "framer-motion";
import { useEffect, useRef } from "react";

export interface RVOAnimateFadeProps extends ComponentWithChildrenProps {
  delay?: number;
  animationProps?: AnimationProps;
  key?: string | number;
}

const RVOAnimateFade = ({
  children,
  delay,
  animationProps,
  key,
}: RVOAnimateFadeProps): JSX.Element => {
  const variants = {
    hidden: { opacity: 0, y: 50 }, // Start position for the animation
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        delay: delay ?? 0,
      },
    },
  };
  const controls = useAnimationControls();
  const ref = useRef(null);
  const isInView = useInView(ref);
  useEffect(() => {
    if (isInView) {
      void controls.start("visible");
    }
  }, [controls, isInView]);
  return (
    <motion.div
      key={key}
      initial="hidden"
      animate={controls}
      variants={variants}
      style={{ position: "relative", width: "100%", height: "100%" }}
      {...animationProps}
      ref={ref}
    >
      {children}
    </motion.div>
  );
};

export default RVOAnimateFade;
