import { Typography } from "@material-ui/core";
import { Seo } from "gatsby-plugin-wpgraphql-seo";
import { useTranslation } from "react-i18next";
import { useSinglePageByDatabaseId } from "../../queries/wpPage/useSinglePageByDatabaseId";
import {
  BackgroundColors,
  HeadlineComponent,
} from "../03-organisms/RVOFeaturedTextSection/RVOFeaturedTextInterfaces";
import RVOFeaturedTextSection from "../03-organisms/RVOFeaturedTextSection/RVOFeaturedTextSection";
import { useRVOFeaturedTextStyles } from "../03-organisms/RVOFeaturedTextSection/RVOFeaturedTextStyles";
import RVORegisterTodayCtaSection from "../03-organisms/RVORegisterTodayCtaSection/RVORegisterTodayCtaSection";
import RVORoadmap from "../03-organisms/RVORoadmap/RVORoadmap";
import RVOTeamMemberGrid from "../03-organisms/RVOTeamMemberGrid/RVOTeamMemberGrid";

export const UeberResolvioPage = (): JSX.Element => {
  const ueberResolvioPage = useSinglePageByDatabaseId(1463);
  const classes = useRVOFeaturedTextStyles();

  const { t } = useTranslation();

  return (
    <>
      <Seo post={ueberResolvioPage} />

      <RVOFeaturedTextSection
        pretext={t("aboutUsPage.intro.pretext")}
        pretextComponent={HeadlineComponent.H1}
        xxlHeadline={true}
        headline={t("aboutUsPage.intro.title")}
        headlineCentered={true}
      >
        <>
          <Typography
            style={{ textAlign: "center" }}
            variant="body1"
            component="p"
          >
            {t("aboutUsPage.intro.text1")}
          </Typography>

          <br />

          <Typography
            style={{ textAlign: "center" }}
            variant="body1"
            component="p"
          >
            {t("aboutUsPage.intro.text2")}
          </Typography>

          <br />

          <Typography
            style={{ textAlign: "center" }}
            variant="body1"
            component="p"
          >
            {t("aboutUsPage.intro.text3")}
          </Typography>
        </>
      </RVOFeaturedTextSection>

      <RVOFeaturedTextSection
        wide={true}
        backgroundColored={BackgroundColors.BlueLight}
        headline={t("aboutUsPage.roadmap.title")}
        headlineComponent={HeadlineComponent.H2}
        headlineCentered={true}
      >
        <>
          <Typography
            variant="body1"
            component="p"
            className={classes.roadmapDescription}
          >
            {t("aboutUsPage.roadmap.text")}{" "}
          </Typography>

          <br />

          <br />

          <RVORoadmap />
        </>
      </RVOFeaturedTextSection>

      <RVOFeaturedTextSection
        wide={true}
        headline={t("aboutUsPage.team.headline")}
        headlineComponent={HeadlineComponent.H2}
        headlineCentered={true}
      >
        <RVOTeamMemberGrid />
      </RVOFeaturedTextSection>

      <RVORegisterTodayCtaSection />
    </>
  );
};

export default UeberResolvioPage;
