import { createStyles, makeStyles } from "@material-ui/core";
import { fontWeightBold } from "../../../layouts/theme";

export const useRVOTeamMemberCardStyles = makeStyles((theme) =>
  createStyles({
    card: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      height: "100%",
      width: "100%",
      padding: 0,
    },
    gridContainer: {
      height: "100%",
    },
    leftContent: {
      height: "100%",
      "& > img": {
        width: "100%",
        objectFit: "cover",
        height: "100%",
      },
    },
    rightContent: {
      padding: theme.spacing(2),
      height: "100%",
      backgroundColor: theme.palette.secondary.light,
    },
    name: {
      textAlign: "left",
      marginBottom: theme.spacing(0.5),
      fontWeight: fontWeightBold,
    },
    position: {
      textAlign: "left",
      marginBottom: theme.spacing(1),
      color: theme.palette.warning.dark,
    },
    description: {},
  })
);
