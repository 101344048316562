/* eslint-disable no-nested-ternary */
import {
  Grid,
  Step,
  StepButton,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  DescriptionOutlined,
  GroupOutlined,
  StarBorderOutlined,
} from "@material-ui/icons";
import { assertTruthy } from "@nvon/baseline-cjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import theme from "../../../layouts/theme";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import RVORegisterNowCtaButton from "../../01-atoms/RVORegisterNowCtaButton/RVORegisterNowCtaButton";
import RVOModal from "../../02-molecules/RVOModal/RVOModal";
import { useRVORoadmapStyles } from "./RVORoadmapStyles";

export default function RVORoadmap(): JSX.Element {
  const hero = useSinglePage("140")?.pageFields?.home?.hero;
  const buttonLabel = hero?.buttonLabel;

  const classes = useRVORoadmapStyles();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState<number | null>(null);

  assertTruthy(
    activeStep === null ||
      activeStep === 1 ||
      activeStep === 2 ||
      activeStep === 3,
    "Only steps 1,2,3 supported right now"
  );

  const activeContent = activeStep
    ? {
        title: t(`aboutUsPage.roadmap.milestones.${activeStep}.title`),
        fact1: t(`aboutUsPage.roadmap.milestones.${activeStep}.fact1`),
        fact2: t(`aboutUsPage.roadmap.milestones.${activeStep}.fact2`),
      }
    : null;

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={classes.wrapper}>
      <Stepper
        classes={{
          vertical: classes.stepperVertical,
        }}
        alternativeLabel={true}
        orientation={isMobile ? "vertical" : "horizontal"}
        activeStep={2}
        connector={
          <StepConnector
            classes={{
              root: classes.connector,
              completed: classes.connectorCompleted,
              line: classes.connectorLine,
              lineVertical: classes.connectorLineVertical,
            }}
          />
        }
      >
        <Step key={1} completed={true}>
          <StepButton
            onClick={() => setActiveStep(1)}
            className={classes.stepButton}
          >
            <StepLabel
              StepIconComponent={() => <DescriptionOutlined />}
              classes={{
                labelContainer: classes.labelContainer,
              }}
            >
              <div className={classes.stepper}>
                <Typography variant="body1">
                  {t("aboutUsPage.roadmap.milestones.1.date")}
                </Typography>

                <br />

                <Typography variant="subtitle1">
                  {t("aboutUsPage.roadmap.milestones.1.shortTitle")}
                </Typography>
              </div>
            </StepLabel>
          </StepButton>
        </Step>

        <Step key={2} completed={true}>
          <StepButton
            color="inherit"
            onClick={() => setActiveStep(2)}
            className={classes.stepButton}
          >
            <StepLabel
              StepIconComponent={() => <GroupOutlined />}
              classes={{
                labelContainer: classes.labelContainer,
              }}
            >
              <div className={classes.stepper}>
                <Typography variant="body1">
                  {t("aboutUsPage.roadmap.milestones.2.date")}{" "}
                </Typography>

                <br />

                <Typography variant="subtitle1" className={classes.stepper}>
                  {t("aboutUsPage.roadmap.milestones.2.shortTitle")}
                </Typography>
              </div>
            </StepLabel>
          </StepButton>
        </Step>

        <Step key={4} completed={false}>
          <StepButton
            color="inherit"
            onClick={() => setActiveStep(3)}
            className={classes.stepButton}
          >
            <StepLabel
              StepIconComponent={() => <StarBorderOutlined />}
              classes={{
                labelContainer: classes.labelContainer,
              }}
            >
              <div className={classes.stepper}>
                <Typography variant="body1">
                  {t("aboutUsPage.roadmap.milestones.3.date")}
                </Typography>

                <br />

                <Typography variant="subtitle1">
                  {t("aboutUsPage.roadmap.milestones.3.shortTitle")}
                </Typography>
              </div>
            </StepLabel>
          </StepButton>
        </Step>
      </Stepper>

      <Typography variant="caption">
        {t("aboutUsPage.roadmap.milestones.hinweis")}
      </Typography>

      <RVOModal
        isOpen={activeStep !== null}
        onClose={() => setActiveStep(null)}
        title={activeContent?.title}
      >
        <Typography variant="body1">{activeContent?.fact1}</Typography>

        <br />

        <Typography variant="body1">{activeContent?.fact2}</Typography>

        <br />

        {activeStep === 1 ? (
          <>
            <Typography variant="body1">
              {t(
                "aboutUsPage.roadmap.milestones.1.invitationBeschlussgenerator"
              )}
            </Typography>

            <br />

            <Grid item={true} className={classes.buttonWrapper}>
              <RVORegisterNowCtaButton label={buttonLabel} />
            </Grid>
          </>
        ) : (
          ""
        )}
      </RVOModal>
    </div>
  );
}
